<template lang="html">
  <div>
    <div
      class="lightbox lightbox--collection coll-detail-lb"
      :class="{ opened: collectionDetailOpened }"
    >
      <div class="wrapper">
        <div class="lightbox__wrap">
          <div class="close-btn" @click="collectionClosed">
            <!-- <div></div> -->
          </div>
          <div class="inner">
            <div class="row inner--scroll">
              <div class="col-12 col-xl-8">
                <div class="inner--left">
                  <div class="top--section">
                    <div class="title">
                      {{
                        lang == "en"
                          ? collections.title_en
                          : collections.title_tc
                      }}
                    </div>
                    <div class="coll-detail--nav">
                      <!-- Left -->
                      <!-- Right -->
                    </div>
                  </div>
                  <div class="gallery--thumbs">
                    <swiper
                      ref="thumbsSwiper"
                      class="swiper"
                      :options="swiperOption"
                      @slideChangeTransitionStart="handleThumbSlideChange"
                    >
                      <swiper-slide
                        v-for="(record, index) in collections.records"
                        :key="index"
                        ><img
                          class="thumbs"
                          :src="
                            `${s3RootPath}${record.gallery[0].image}` | imageResizedPath('medium')
                          "
                        />
                      </swiper-slide>

                      <div
                        class="swiper-button-next small-bk small-bk-next "
                        slot="button-next"
                      ></div>
                      <div
                        class="swiper-button-prev small-bk small-bk-prev"
                        slot="button-prev"
                      ></div>
                    </swiper>
                  </div>

                  <div class="gallery--main">
                    <div class="record--detail--gallery--wrapper">
                      <div class="group--wrapper">
                        <ul class="gallery--list" v-show="galleryGridView">
                          <li
                            class="gallery--list--item"
                            v-for="(item, index) in recordDetail.gallery"
                            @click="handleGalleryItem(index)"
                          >
                            <img
                              :src="`${s3RootPath}${item.image}` | imageResizedPath('medium')" 
                              class="item--image"
                              alt=""
                            />
                          </li>
                        </ul>
                        <div
                          class="grid-view"
                          @click="handleGridView"
                          v-if="!galleryGridView"
                        >
                          <span>
                            {{ $t("collection.collection_gridview") }}</span
                          >
                        </div>

                        <swiper
                          ref="mySwiper"
                          :options="swiperMainOptions"
                          @slideChangeTransitionEnd="handleSlideChange"
                          v-show="!galleryGridView"
                        >
                          <swiper-slide v-for="item in recordDetail.gallery">
                            <div class="swiper-slide-wrapper">
                              <div class="swiper-zoom-container">
                                <div class="rotate-wrapper swiper-zoom-target">
                                  <img
                                    draggable="false"
                                    :src="
                                      `${s3RootPath}${item.image}` | imageResizedPath('full')
                                    "
                                    class="swiper-lazy"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              class="swiper-lazy-preloader swiper-lazy-preloader-white"
                            ></div>
                          </swiper-slide>
                          <!-- <swiper-slide>
										<div class="swiper-slide-wrapper">
											<div class="swiper-zoom-container">
												<div class="rotate-wrapper swiper-zoom-target">
													<img
														draggable="false"
														src="https://picsum.photos/720/1280"
														class="swiper-lazy"
													/>
												</div>
											</div>
										</div>
										<div
											class="swiper-lazy-preloader swiper-lazy-preloader-white"
										></div>
									</swiper-slide> -->

                          <div
                            class="swiper-button-next swiper-button-white "
                            slot="button-next"
                          ></div>
                          <div
                            class="swiper-button-prev swiper-button-white"
                            slot="button-prev"
                          ></div>
                          <div
                            class="swiper-pagination"
                            slot="pagination"
                          ></div>
                        </swiper>

                        <div
                          class="swiper--tools--wrapper"
                          v-show="!galleryGridView"
                        >
                          <div class="swiper--tools">
                            <div class="swiper--tools--list">
                              <div class="tool zoom-out" @click="zoomOutImage">
                                <svg width="16" height="17" viewBox="0 0 16 17">
                                  <g
                                    id="Group_223"
                                    data-name="Group 223"
                                    transform="translate(-0.232 0.44)"
                                  >
                                    <path
                                      id="Path_233"
                                      data-name="Path 233"
                                      d="M0,0H9.572"
                                      transform="translate(3.638 8.82)"
                                      fill="none"
                                      stroke="#000"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                    />
                                    <rect
                                      id="Rectangle_214"
                                      data-name="Rectangle 214"
                                      width="16"
                                      height="17"
                                      transform="translate(0.232 -0.44)"
                                      fill="none"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div class="tool zoom-in" @click="zoomInImage">
                                <svg width="16" height="17" viewBox="0 0 16 17">
                                  <g
                                    id="Group_222"
                                    data-name="Group 222"
                                    transform="translate(-0.232 0.097)"
                                  >
                                    <path
                                      id="Path_231"
                                      data-name="Path 231"
                                      d="M0,0H9.572"
                                      transform="translate(8.423 3.356) rotate(90)"
                                      fill="none"
                                      stroke="#000"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                    />
                                    <path
                                      id="Path_232"
                                      data-name="Path 232"
                                      d="M0,0H9.572"
                                      transform="translate(3.638 8.142)"
                                      fill="none"
                                      stroke="#000"
                                      stroke-linecap="round"
                                      stroke-width="1"
                                    />
                                    <rect
                                      id="Rectangle_213"
                                      data-name="Rectangle 213"
                                      width="16"
                                      height="17"
                                      transform="translate(0.232 -0.097)"
                                      fill="none"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div class="tool rotate" @click="rotateImage">
                                <svg
                                  id="two-circular-counterclockwise-rotating-arrows-symbol"
                                  width="14.945"
                                  height="14.945"
                                  viewBox="0 0 14.945 14.945"
                                >
                                  <g id="_x33__5_">
                                    <g id="Group_155" data-name="Group 155">
                                      <path
                                        id="Path_160"
                                        data-name="Path 160"
                                        d="M14.478,9.341H9.808a.467.467,0,0,0,0,.934h3.566A6.529,6.529,0,0,1,1.008,8.407H.064a7.468,7.468,0,0,0,13.947,2.681v3.391a.467.467,0,0,0,.934,0V9.808A.435.435,0,0,0,14.478,9.341ZM.467,5.6h4.67a.467.467,0,0,0,0-.934H1.569A6.531,6.531,0,0,1,13.937,6.539h.944A7.468,7.468,0,0,0,.934,3.858V.467A.467.467,0,0,0,0,.467v4.67A.47.47,0,0,0,.467,5.6Z"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="inner--right">
                  <div class="record__detail__info">
                    <!-- Info Wrapper -->
                    <div class="record__detail__info__wrapper">
                      <h1 class="title">
                        {{
                          lang == "en"
                            ? recordDetail.title_en
                            : recordDetail.title_tc
                        }}
                      </h1>
                      <p class="description">
                        {{
                          lang == "en"
                            ? recordDetail.description_en
                            : recordDetail.description_tc
                        }}
                      </p>

                      <div class="record__detail__data__list">
                        <!-- Taxonomy  -->
                        <div class="record__detail__data__single">
                          <p>UID</p>
                          <ul>
                            <li>{{ recordDetail.uid | upper }}</li>
                          </ul>
                        </div>
                        <!-- End Taxonomy -->
                        <!-- Taxonomy  -->
                        <div class="record__detail__data__single">
                          <p>
                            {{ $t("collection.collection_media_type") }}
                          </p>
                          <ul>
                            <li v-for="el in mediaTypeEl" :key="el.slug">
                              {{ lang == "en" ? el.title_en : el.title_tc }}
                            </li>
                          </ul>
                        </div>
                        <!-- End Taxonomy -->
                        <!-- Taxonomy  -->
                        <div class="record__detail__data__single">
                          <p>
                            {{ $t("collection.collection_primary_elements") }}
                          </p>
                          <ul>
                            <li v-for="el in primaryEl" :key="el.slug">
                              {{ lang == "en" ? el.title_en : el.title_tc }}
                            </li>
                          </ul>
                        </div>
                        <!-- End Taxonomy -->
                        <!-- Taxonomy  -->
                        <div class="record__detail__data__single">
                          <p>{{ $t("collection.collection_dimensions") }}</p>
                          <ul>
                            <li>{{ recordDetail.dimensions }}</li>
                          </ul>
                        </div>
                        <!-- End Taxonomy -->
                        <!-- Taxonomy  -->
                        <div class="record__detail__data__single">
                          <p>
                            {{ $t("collection.collection_year_of_release") }}
                          </p>
                          <ul>
                            <li>
                              {{ recordDetail.start_year }} -
                              {{ recordDetail.end_year }}
                            </li>
                          </ul>
                        </div>
                        <!-- End Taxonomy -->
                        <!-- Meta  -->
                        <div
                          v-for="el in this.recordDetail.meta"
                          :key="el.id"
                          class="record__detail__data__single"
                        >
                          <p>{{ lang == "en" ? el.key_en : el.key_tc }}</p>
                          <ul>
                            <li>
                              {{ lang == "en" ? el.value_en : el.value_tc }}
                            </li>
                          </ul>
                        </div>
                        <!-- End Meta -->
                      </div>
                    </div>
                    <!-- End Info Wrapper -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- inner end -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
  name: "CollectionDetailModal",
  data() {
    return {
      lang: Cookies.get("HKGA-MAINSITE-LANGUAGE"),
      galleryGridView: false,
      rotateDegress: 0,
      recordDetail: {},
      primaryEl: null,
      mediaTypeEl: null,
      collectionIdList: null,
      swiperMainOptions: {
        zoom: {
          maxRatio: 2,
          toggle: false
        },
        // allowTouchMove: false,
        centeredSlides: true,
        preloadImages: false,
        lazy: true,
        centeredSlidesBounds: true,
        pagination: {
          el: ".swiper-pagination",
          type: "fraction"
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        breakpoints: {
          // when window width is >= 640px
          1200: {
            allowTouchMove: false
          }
        }
      },
      swiperOption: {
        // loop: true,
        // width: 50,

        // loopedSlides: 8,
        initialSlide: 0,
        slideToClickedSlide: true,
        // allowTouchMove:true,
        slidesPerView: "auto",
        // slidesPerColumnFill: "row",
        // watchSlidesProgress:true,
        spaceBetween: 8,
        centeredSlides: true,
        // slidesPerGroup: 1,
        // breakpoints: {
        //   768: {
        //     slidesPerView: 5,
        //     spaceBetween: 20
        //   },
        //   1024: {
        //     slidesPerView: 6,
        //     spaceBetween: 20
        //   }
        // },
        navigation: {
          nextEl: ".small-bk-next",
          prevEl: ".small-bk-prev"
        }
      }
    };
  },

  props: {
    collections: Object,
    collectionDetailOpened: Boolean,
    collectionThumbsIndex: Number
  },

  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    swiperSecond() {
      return this.$refs.thumbsSwiper.$swiper;
    }
  },

  beforeMount() {
    this.s3RootPath = this.s3Path;
  },

  mounted() {
    console.log("Current Swiper instance object", this.swiper);
    console.log("Second Swiper instance object", this.swiperSecond);
    this.scrollGalleryPin();
  },

  filters: {
    upper(value) {
      if (!value) return "";
      return value.toUpperCase();
    }
  },

  methods: {
    async apiRecordDetail(slug) {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/record/${slug}?meta=1&term=1&collection=1`,
          {
            method: "GET",
            mode: "cors",
            cache: "default"
          }
        );
        if (!response.ok) {
          throw new Error("API Error");
        }
        this.recordDetail = await response.json();

        this.primaryEl = this.recordDetail.term.filter(
          term => term.taxonomy.slug == "primary-elements"
        );

        this.mediaTypeEl = this.recordDetail.term.filter(
          term => term.taxonomy.slug == "media-type"
        );

        this.collectionIdList = this.recordDetail.collection.map(
          item => item.id
        );
      } catch (error) {
        console.log(error);
      }
    },

    handleThumbClick(e) {
      console.log(e);
      this.swiperSecond.slideTo(e, 10, false);
    },

    handleGalleryItem(index) {
      this.galleryGridView = false;
      this.swiper.slideTo(index, 10, false);
      this.swiper.lazy.loadInSlide(index);
    },

    handleGridView() {
      this.galleryGridView = !this.galleryGridView;
    },

    clickedRecord(index, slug) {
      console.log(index);
      console.log(slug);
      ScrollTrigger.getAll().forEach(st => st.kill());

      this.recordDetail = this.apiRecordDetail(slug);

      setTimeout(() => {
        this.swiperSecond.slideTo(index, 400, false);
      }, 100);

      console.log(this.swiperSecond.activeIndex);
    },

    rotateImage() {
      let activeSlideEl = this.swiper.slides[this.swiper.activeIndex];
      setTimeout(() => {
        if (this.rotateDegress == 0) {
          activeSlideEl.querySelector(
            ".rotate-wrapper"
          ).style.transitionProperty = "unset";
        }

        this.rotateDegress += 90;

        // this.swiper.slides[
        //   this.swiper.activeIndex
        // ].style.webkitTransform = `rotate(${this.rotateDegress}deg)`;

        let imageWidth = activeSlideEl.querySelector(".swiper-lazy").width;

        let imageHeight = activeSlideEl.querySelector(".swiper-lazy").height;

        if (
          imageWidth > imageHeight &&
          (this.rotateDegress == 90 || this.rotateDegress == 270)
        ) {
          this.scaleRatio = 0.7;
        } else if (imageWidth > imageHeight) {
          this.scaleRatio = 1;
        }

        if (
          imageHeight > imageWidth &&
          (this.rotateDegress == 90 || this.rotateDegress == 270)
        ) {
          this.scaleRatio = 1;
        } else if (imageHeight > imageWidth) {
          this.scaleRatio = 0.7;
        }

        activeSlideEl.querySelector(
          ".rotate-wrapper"
        ).style.webkitTransform = `rotate(${this.rotateDegress}deg) scale(${this.scaleRatio})`;
        if (this.rotateDegress == 360) {
          setTimeout(() => {
            activeSlideEl.querySelector(
              ".rotate-wrapper"
            ).style.webkitTransform = "none";
            activeSlideEl.querySelector(
              ".rotate-wrapper"
            ).style.transitionProperty = "none";
          }, 300);

          this.rotateDegress = 0;
        }
      }, 400);
    },

    zoomInImage() {
      let activeSlideEl = this.swiper.slides[this.swiper.activeIndex];

      this.swiper.zoom.in();
      activeSlideEl.querySelector(
        ".rotate-wrapper"
      ).style.webkitTransform = `rotate(${this.rotateDegress}deg) scale(${this.scaleRatio})`;
      this.swiper.navigation.nextEl.classList.add("swiper-button-disabled");
      this.swiper.navigation.prevEl.classList.add("swiper-button-disabled");
    },

    zoomOutImage() {
      let activeSlideEl = this.swiper.slides[this.swiper.activeIndex];

      this.swiper.zoom.out();
      activeSlideEl.querySelector(
        ".rotate-wrapper"
      ).style.webkitTransform = `rotate(${this.rotateDegress}deg) scale(${this.scaleRatio})`;
      this.swiper.navigation.nextEl.classList.remove("swiper-button-disabled");
      this.swiper.navigation.prevEl.classList.remove("swiper-button-disabled");
      this.swiper.navigation.init();
      this.swiper.navigation.update();
    },

    handleSlideChange() {
      this.rotateDegress = 0;
    },

    handleThumbSlideChange() {
      this.galleryGridView = false;

      this.swiper.slideTo(0, 10, false);
      this.apiRecordDetail(
        this.collections.records[this.swiperSecond.realIndex].slug
      );
    },

    scrollGalleryPin() {
      let _this = this;
      ScrollTrigger.matchMedia({
        "(min-width: 1200px) and (min-height: 500px)": function() {
          gsap.timeline({
            scrollTrigger: {
              scroller: ".inner--scroll",
              // scroller:".lightbox__wrap",
              trigger: ".inner--right",
              // trigger:".record__detail__info",
              start: "top top",
              // end: "bottom+=100 top",

              // end: () => `+=${_this.$refs.sectionInfo.offsetHeight} bottom`,
              end: "bottom bottom",
              // markers: true,
              scrub: true,
              pin: ".inner--left"
              // pinSpacing:"margin",
            }
          });
        }
      });
    },

    collectionClosed() {
      this.$emit("collectionDetailClosed", {
        modalState: false
      });
      this.recordDetail = {};
      // this.setPreventScrolling(false);
    }
  }
};
</script>

<style lang="scss" scoped>
.record {
  &--detail {
    &--wrapper {
      // display: flex;
      // height: 100%;
      margin-bottom: 40px;
    }
    &--gallery {
      &--wrapper {
        display: flex;
        // margin-right: 30px;
        position: relative;
        // height:100vh;
        // height: calc(100vh - 40px);
        height: auto;
        flex-flow: column;
        align-content: space-between;
        box-sizing: border-box;
        height: 100%;
        // @include respond-to(screen-md) {
        //   height: calc(100vh - 40px);
        // }
        // @include respond-to(screen-lg) {
        //   height: calc(100vh - 40px);
        // }
        // @include respond-to(screen-xl) {
        //   height: calc(100vh - 40px);
        //   // height: auto;
        // }
        .swiper-container {
          @include respond-to(screen-xl) {
            // height: 90%;
          }
        }
        .swiper {
          &--tools {
            padding-right: 0px;
            @include respond-to(screen-md) {
              padding-right: 0;
            }
            &--wrapper {
              z-index: 2;
              position: absolute;
              // right: 0;
              // bottom: 8%;
              right: 0px;
              // bottom: 40px;

              // edit by carol
              // position: relative;
              // bottom: 30px;
              // bottom:20px;
              // right: 10px;
              // bottom: 50%;
              // transform: translateY(calc(-50% + 60px));
              // z-index: 999;
              bottom: 40px;
              @include respond-to(screen-md) {
                // bottom:10px;
              }
              @include respond-to(screen-lg) {
                bottom: 40px;
              }
              @include respond-to(screen-xl) {
                bottom: 40px;
              }
            }
            &--list {
              display: flex;
              // flex-direction: column;

              // edit by carol

              .tool {
                // margin-top: 10px;
                margin-left: 10px;
                // background-color: rgba($color-white, .5);
                cursor: pointer;
                position: relative;
                // background-color: rgba($color-white, 0.5);
                // padding: 5px;

                // &:after{
                // 	content:'';
                // 	display: block;
                // 	padding-top: 100%;
                // 	background-color: rgba($color-white, .5);
                // }
              }
            }
          }
        }
        .swiper-container {
          // height: calc(50vh - 100px);
          // padding-bottom: 85px;
          margin-bottom: 30px;
          //   max-width: 980px;
          position: relative;
          overflow: hidden;
          width: 100%;
          // height: calc(100vh - 120px);
          // @include respond-to (screen-lg){
          // 	height:auto;
          // }
          .swiper-slide {
            // background-color: #d9d9d9;

            transition: all 200ms ease-in-out;
            &-wrapper {
              // position: relative;
              // height: 100%;
              // width: 100%;
              // overflow: hidden;

              //edit by carol
              width: 100%;
              height: 100%;
              // padding: 50px;
              display: flex;
              // justify-content: center;
              // align-items: center;
              position: relative;
              overflow: hidden;
              margin-bottom: 15px;
              @include respond-to(screen-md) {
                // max-height: 500px;
                // max-height: calc(100vh - 160px);
                // max-width: 800px;
                min-height: 500px;
                margin-bottom: 20px;
                max-height: calc(100vh - 350px);
              }
              @include respond-to(screen-lg) {
                height: 100%;

                // max-width: 800px;
                // max-height: 100%;
                // max-height: calc(100vh - 160px);
                // max-height: 500px;

                min-height: 500px;
                max-height: calc(100vh - 350px);
                margin-left: auto;
                margin-right: auto;
              }

              @include respond-to(screen-xl) {
                min-height: 500px;
                max-height: calc(100vh - 300px);
                max-width: 980px;
                // margin-bottom: 20px;
                // height: calc(100vh - 160px);
                margin-left: auto;
                margin-right: auto;
              }
            }
            .swiper-zoom-container {
              // position: absolute;
              // top: 0;
              // left: 0;
              // width: 100%;
              // height: 100%;
              display: block !important;
              &:after {
                content: "";
                display: block;
                padding-top: 75%;
                // padding-top: 56.25%;
                // position:absolute;
                // top:0;
                // left:0;
              }
            }
            .rotate-wrapper {
              transition: all 200ms ease-in-out;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
            img {
              // width: auto;
              // height: 100%;
              // object-fit: contain;
              transition: transform 0.5s ease-in;
              // // height: 100%;
              // margin: auto;
              // display: flex;

              //edit by carol
              object-fit: contain;
              // max-width: 100%;
              // max-height: 100%;
              width: 100%;
              height: 100%;
              // width: auto;
              // height: auto;
            }
          }

          .swiper-pagination {
            text-align: left;
            display: inline-block;
            width: auto;
            position: relative;
            padding-left: 0px;
            @include respond-to(screen-md) {
              padding-left: 0px;
            }
          }
          .swiper-pagination-fraction {
            // bottom: -30px;
            // position: absolute;
            z-index: 9;
            bottom: 0;
          }
          .swiper-button {
            &-prev {
              position: absolute;
              top: 0;
              left: 0;
              width: 50%;
              height: 100%;
              // height: 120px;
              // cursor: url("~@/assets/images/swiper-prev.png"), auto;
              cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.2' height='100.491' viewBox='0 0 29.2 100.491'%3E%3Cpath id='Path_337' data-name='Path 337' d='M-3477.552-299l-28.19,50,28.19,50' transform='translate(3506.316 299.246)' fill='none' stroke='%23000' stroke-width='1'/%3E%3C/svg%3E%0A"),
                auto;
              &::after {
                content: "";
              }
            }
            &-next {
              position: absolute;
              top: 0;
              right: 0;
              width: 50%;
              height: 100%;
              // height: 120px;
              // cursor: url("~@/assets/images/swiper-next_low_res.png"), auto;
              cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.199' height='100.491' viewBox='0 0 29.199 100.491'%3E%3Cpath id='Path_336' data-name='Path 336' d='M-3505.742-299l28.19,50-28.19,50' transform='translate(3506.178 299.246)' fill='none' stroke='%23000' stroke-width='1'/%3E%3C/svg%3E "),
                auto;
              &::after {
                content: "";
              }
            }
          }
        }
      }
    }
    &--share {
      margin-right: auto;
      display: flex;
      align-items: center;
      h5 {
        font-size: 16px;
        font-family: "Graphik Compact", sans-serif;
        font-weight: 100;
        // margin-bottom: 10px;
        line-height: 1;
        margin-right: 20px;
      }

      &--list {
        .icon {
          width: 15px;
          height: 15px;
          margin-right: 15px;
          cursor: pointer;
        }
      }
    }
    &--collection {
      // position: relative;
      // top: -20px;
      // margin-top: 20px;
      // position: absolute;
      // position: relative;
      // right: 0;
      // bottom: 85px;
      width: auto;
      z-index: 1;
      // position: absolute;
      // bottom: 80px;
      // right: 0;
      .add--button {
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        // margin-bottom: 30px;
        @include transition(all 300ms ease-in-out);
        .plus {
          display: inline-block;
          width: 20px;
          height: 20px;
          background: linear-gradient(#000, #000), linear-gradient(#000, #000);
          background-position: center;
          background-size: 50% 2px, 2px 50%; /*thickness = 2px, length = 50% (25px)*/
          background-repeat: no-repeat;
        }
        > p {
          display: inline-block;
          font-size: 16px;
        }
        &:hover {
          opacity: 0.5;
        }
      }
    }
    &--related {
      margin-top: 50px;
      padding: 0 20px;
      margin-bottom: 60px;
      @include respond-to(screen-md) {
        margin-top: 50px;
        padding: 0;
        margin-bottom: 60px;
      }
      @include respond-to(screen-lg) {
        margin-top: 100px;
      }
      h2 {
        font-family: "Graphik Compact", sans-serif;
        font-weight: 100;
        font-size: 32px;
        margin-bottom: 24px;
      }
      &--list {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
      }
    }
  }

  &__detail {
    padding-top: 0px !important;
    padding-bottom: 40px;
    @include respond-to(screen-md) {
      padding-top: 40px !important;
    }

    &__info {
      // overflow-y: scroll;
      // width: 30%;
      // height: calc(100vh - 245px);
      // padding-left:20px;
      // margin-top:60px;
      padding-left: 20px;
      padding-right: 20px;
      @include respond-to(screen-md) {
        padding-left: 20px;
        padding-right: 20px;
      }
      @include respond-to(screen-lg) {
        padding-left: 20px;
        padding-right: 20px;
      }
      @include respond-to(screen-xl) {
        padding-left: 20px;
        padding-left: 20px;
      }
      &__wrapper {
        padding-bottom: 0px;
        @include respond-to(screen-md) {
          padding-bottom: 30px;
        }
        @include respond-to(screen-xl) {
          padding-bottom: 30px;
        }
        .title {
          font-size: 24px;
          line-height: 1.2;
          font-family: "Graphik Compact", sans-serif;
          margin-bottom: 25px;
          font-weight: 400;
          @include respond-to(screen-sm) {
            padding-bottom: 34px;
          }
        }
        .description {
          font-size: 16px;
          @include respond-to(screen-sm) {
            padding-bottom: 18px;
          }
        }
      }
    }
    &__data {
      &__list {
        margin-top: 40px;
      }
      &__single {
        margin-bottom: 20px;
        text-transform: capitalize;
        p {
          color: #7c7c7c;
          font-size: 14px;
        }
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            font-size: 16px;
          }
        }
      }
      &__single:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.lightbox {
  position: fixed;
  @include center-center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  @include transition(0.3s ease-in-out);
  &__wrap {
    position: relative;
    @include transform(translateY(40px));
    @include transition(0.3s ease-in-out);
  }
  &.opened {
    opacity: 1;
    visibility: visible;
    .lightbox__wrap {
      @include transform(translateY(0));
    }
  }
}

.close-btn:hover:before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.close-btn:hover:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

// .close-btn > div {
//   position: relative;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
// }

.close-btn:before,
.close-btn:after {
  content: "";
  display: block;
  position: absolute;
  width: 15px;
  height: 1px;
  // background: #f6f4f0;
  background: black;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  @include respond-to(screen-md) {
    width: 15px;
    height: 2px;
  }
}

.close-btn:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.close-btn:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.lightbox {
  opacity: 0;
  z-index: -1;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  // background: rgba(34, 35, 35, 0.9);
  background-color: rgba($color-faintgrey, 0.5);
  -webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness(100%);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.lightbox.opened {
  z-index: 105;
  opacity: 1;
}

.lightbox .close-btn {
  position: absolute;
  // right: 10px;
  // top: 10px;
  //
  // @include respond-to(screen-md) {
  // 	right: 30px;
  // 	top: 30px;
  // }
}

.lightbox .close-btn:before,
.lightbox .close-btn:after {
  width: 20px;
  @include respond-to(screen-md) {
    width: 30px;
  }
}
.close-btn {
  width: 20px;
  height: 20px;
  align-items: center;
  display: flex;
  // overflow: hidden;
  cursor: pointer;
  // z-index: 99;
  z-index: 100;
  top: -40px !important;
  right: 0 !important;
  @include respond-to(screen-md) {
    width: 30px;
    height: 30px;
  }
}

.lightbox--video {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.lightbox--video .wrapper {
  max-width: 1080px;
  position: relative;
  width: 100%;
  padding: 20px;
  overflow: hidden;
  @include respond-to(screen-md) {
    padding: 40px;
  }
}

.lightbox--video .wrapper iframe,
.lightbox--video .wrapper video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.lightbox__wrap {
  width: 100%;
  height: auto;
  position: relative;
  // padding-top: 62.5%;
  .lightbox-vid {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    top: 0 !important;
  }
}

.lightbox {
  .wrapper {
    min-width: 90%;
    background-color: $color-faintgrey;
    // padding: 40px;
    color: black;
    @include respond-to(screen-xl) {
      min-width: 1280px;
    }
  }
}
.inner-container {
  padding: 0 20px;
  @include respond-to(screen-sm) {
    padding: 0 40px;
  }
}
.inner {
  .collection {
    &--list {
      height: 100%;
      // overflow-y: scroll;
      overflow-y: auto;
      background-color: #e6e6e6;
      list-style: none;
      margin: 0;
      padding: 0;
    }
    &--item {
      padding: 20px 18px;
      border-bottom: 1px solid $color-black;
      cursor: pointer;
      &:hover {
        background: $color-black;
        p {
          color: $color-white;
        }
      }
      &--wrapper {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        img {
          width: 44px;
          height: 44px;
        }
        p {
          @include transition(all 300ms ease-in-out);
          font-size: 16px;
          margin-left: 16px;
        }
      }
    }
    &--successful {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      align-content: center;
      h1 {
        font-family: "Graphik Compact", sans-serif;
        font-weight: 400;
        font-size: 20px;
      }
    }
  }
}

.coll-detail-lb {
  .wrapper {
    // width: 90%;
    // // height: 800px;
    // @include respond-to(screen-md) {
    //   width: 1280px;
    // }
  }
  .inner {
    position: relative;
    // overflow: scroll;
    // height: 800px;
    // height: calc(100vh - 80px);
    height: 100vh;
    // height:auto ;
    // min-height: calc(100vh - 80px);;
    width: 100%;
    display: flex;
    flex-flow: column;
    // padding-top: 50px;
    // padding-left: 40px;
    // padding-right: 40px;
    &--scroll {
      overflow-y: scroll;
    }
    &--left {
      padding-top: 80px;
      padding-bottom: 30px;
      @include respond-to(screen-md) {
        padding-top: 50px;
        padding-bottom: 50px;
      }
    }
    &--right {
      padding-top: 10px;
      padding-bottom: 80px;
      @include respond-to(screen-md) {
        padding-top: 50px;
        padding-bottom: 50px;
      }
    }
    .top--section {
      margin-bottom: 10px;
      .title {
        font-size: 24px;
        line-height: 1.2;
        margin-bottom: 10px;
      }
    }
    .gallery--thumbs {
      margin-bottom: 30px;
      .swiper {
        &-slide {
          opacity: 0.2;
          transition: opacity 0.2s ease-in-out;
          &:hover {
            opacity: 1;
          }
          &-active {
            opacity: 1;
          }
        }
      }
    }
  }

  .lightbox__wrap {
    height: 100%;
  }
  form {
    width: 500px;
    height: 500px;
    position: relative;
    display: flex;
    flex-flow: column;
    > div {
      position: relative;
      /* margin-bottom: auto; */
      margin: auto;
      width: 100%;
      .edit--submit {
        margin-top: auto;
      }
    }
  }
  .collection--item {
    @include transition(all 300ms ease-in-out);
  }
}
.group--wrapper {
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
  // min-height: 500px;
  // height: calc(100vh - 90px);
  // margin-top: auto;
  .grid-view {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    &:before {
      content: "";
      margin-right: 8px;
      display: inline-block;
      width: 22px;
      height: 22px;
      background-image: url("~@/assets/images/grid-view.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}

.gallery--list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;

  &--item {
    width: auto;
    margin-right: 8px;
    margin-bottom: 8px;
    .item--image {
      height: 70px;
      @include respond-to(screen-md) {
        height: 150px;
      }
    }
  }
}

.lightbox--collection {
  .wrapper {
    // width: 80vw;
    width: 100vw;
    height: 100%;
  }

  .swiper-container {
    // padding: 20px 0 0;
    overflow-x: clip;
    overflow-y: inherit;
  }
  //for preview only
  .swiper-slide {
    text-align: center;
    width: auto;
    img {
      position: relative;
      overflow: hidden;
      width: auto;
      height: 80px;
    }
  }
  .small-bk {
    position: absolute;
    width: 10px;
    height: 10px;
    top: -30px;
    margin: 0;
    &:before {
      content: "";
      // margin-right: 5px;
      display: inline-block;
      width: 10px;
      height: 10px;
      background-image: url(/img/collection--back.bf661215.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
    &:after {
      display: none;
    }
    &-next {
      right: 0;
      &:before {
        transform: scale(-1);
      }
    }
    &-prev {
      right: 15px;
      left: auto;
    }
  }
  .close-btn {
    right: 20px !important;
    top: 20px !important;
    @include respond-to(screen-md) {
      right: 30px !important;
    }
    @include respond-to(screen-lg) {
      right: 30px !important;
      top: 30px !important;
    }
  }
  .record__detail__info__wrapper {
    .title {
      padding-bottom: 0;
    }
  }
  .record__detail__data__list {
    margin-top: 0;
  }
  .inner--left {
    // height: calc(100% - 100px);
    height: auto;
    position: relative;
    display: flex;
    flex-flow: column;
    align-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    // @include respond-to(screen-md){
    //   height: 100vh;
    // }
    @include respond-to(screen-lg) {
      height: 100vh;
      padding-left: 40px;
      padding-right: 0px;
    }
    .top--section,
    .gallery--thumbs,
    .gallery--main {
      position: relative;
    }
  }
  .inner--right {
    padding-right: 20px;
    @include respond-to(screen-md) {
      padding-right: 40px;
    }
  }
  .record--detail--gallery--wrapper .swiper-container {
    margin-bottom: 40px;
  }
}
</style>
